import { query } from './utils/query';

const THEME_PREFIX = 'color-scheme';

const themeToggle = query('.theme-toggle'); // wrapper of theme switch
const themeSwitch = themeToggle?.querySelector('input[type=checkbox]') as HTMLInputElement;

const defaultTheme = localStorage.getItem('color-scheme');

// Use theme value from operating system setting if no theme value stored in local storage
if (!defaultTheme) {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    themeSwitch.checked = true;
    document.body.setAttribute(THEME_PREFIX, 'dark');
  }
  else {
    themeSwitch.checked = false;
    document.body.setAttribute(THEME_PREFIX, 'light');
  }
}
else {
  themeSwitch.checked = defaultTheme === 'dark';
  document.body.setAttribute(THEME_PREFIX, defaultTheme);
}

// Switch theme
themeToggle?.addEventListener('change', () => {
  if (themeSwitch) {
    const data = themeSwitch.dataset;
    const theme = themeSwitch.checked ? data.on : data.off;
    if (theme) {
      document.body.setAttribute(THEME_PREFIX, theme);
      localStorage.setItem(THEME_PREFIX, theme);
    }
  }
});
