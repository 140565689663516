const EXPANDED_SESSION_KEY = 'pandora-nav-expanded';

/**
 * Get expanded item data from session storage key
 * @param navId id of nav
 * @return text of expanded items
 */
export const getExpandedItemSessionData = (navId: string) : string | null => {
  return sessionStorage.getItem(`${EXPANDED_SESSION_KEY}-${navId}`);
};

/**
 * Get expanded item ids from session storage data
 * @param navId id of nav
 * @param expandedItemSessionData expanded item session
 * @return array of expanded item id
 */
export const getExpandedItemIds = (navId: string, expandedItemSessionData?: string | null) : string[] => {
  const data = expandedItemSessionData || getExpandedItemSessionData(navId);
  return data ? data.split(',') : [];
};

/**
 * Set expanded item ids to session storage
 * @param navId id of nav
 * @param itemIds array of item id as string
 * @return {void}
 */
export const setExpandedItemIds = (navId: string, itemIds: string) : void => {
  sessionStorage.setItem(`${EXPANDED_SESSION_KEY}-${navId}`, itemIds);
};

/**
 * Add/Remove an item from array
 * @param arr array of item
 * @param item item to toggle
 * @return toggled array
 */
export const toggleArray = (arr: string[], item: string) : string[] => {
  if (arr.includes(item)) {
    return arr.filter((i: string) => i !== item);
  }

  return [...arr, item];
};
