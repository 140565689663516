import { LitElement, css, html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';

/**
 * A private element to show overlay
 */
@customElement('panel-overlay')
export class PanelOverlay extends LitElement {
  static styles = css`
    :host([opened]) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `;

  @property({ type: Boolean, reflect: true })
  opened = false;

  render () : TemplateResult {
    return html``;
  }
}
