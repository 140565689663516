import { LitElement, css, html, PropertyValues, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { getExpandedItemIds, toggleArray, setExpandedItemIds } from './helpers';

/**
 * Navigation item
 */
@customElement('pandora-nav-item')
export class PandoraNavItem extends LitElement {
  static styles = css`
    :host {
      display: block;
      position: relative;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 30px;
      font-size: 14rem;
      margin: 5px;
      padding: 0 2px;
    }

    :host([collapsible]) {
      cursor: pointer;
      font-weight: 500;
      transition: background-color 130ms ease-out,color 130ms ease-out;
    }

    :host [part=label] {
      cursor: pointer;
      padding: 2px 12px;
      color: var(--primary-text-color);
    }

    :host [part=label]:hover {
      background-color: var(--hover-bg-color);
    }

    :host([selected]) [part=label] {
      color: var(--text-highlight-color);
    }

    :host([selected]:not([collapsible])) [part=label] {
      background-color: var(--hover-bg-color);
    }

    a {
      display: block;
      box-sizing: border-box;
      text-decoration: none;
      color: inherit;
      outline: none;
      width: 100%;
    }
  `;

  @property({ type: String })
  title = '';

  @property({ type: String })
  location = '';

  @property({ type: Boolean, reflect: true })
  collapsible = false;

  @property({ type: Boolean })
  collapsed = false;

  @property({ type: Boolean, reflect: true })
  selected = false;

  @property({ type: String })
  navId = '';

  /**
   * Called when the element’s DOM has been updated and rendered
   * @param changedProperties Properties that has changed
   * @returns {void}
   */
  updated (changedProperties: PropertyValues) : void {
    super.updated(changedProperties);
    if (changedProperties.has('selected') && this.selected) {
      this.collapsed = false;
      // Set selected state to all parent nav items
      if (this.parentElement && this.parentElement instanceof PandoraNavItem) {
        this.parentElement.selected = true;
      }
    }
  }

  /**
   * Change expand state of item
   * @param event click event
   * @returns {void}
   */
  private handleClickExpand (event: MouseEvent) {
    event.stopPropagation();
    if (this.collapsible) {
      this.collapsed = !this.collapsed;
      // add/remove nav id from expanded item session storage data
      const oldExpandedItemIds = getExpandedItemIds(this.navId);
      const newExpandedItemIds = toggleArray(oldExpandedItemIds, this.id);
      setExpandedItemIds(this.navId, newExpandedItemIds.join());
    }
  }

  /**
   * Get label template
   * @returns anchor element if location is defined
   */
  private get LabelTemplate () {
    return this.location ? html`
      <a href=${this.location}>${this.title}</a>` : this.title;
  }

  render () : TemplateResult {
    return html`
      <div part="label" @click=${this.handleClickExpand}>
        ${this.LabelTemplate}
      </div>
      ${!this.collapsed ? html`<slot></slot>` : null}
    `;
  }
}
