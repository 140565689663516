
import { query, queryAll } from './utils/query';

import type { MenuItem } from '@pandora/parser/src/interfaces/MenuItem';
import type { NavItem } from '@pandora/parser/src/interfaces/NavItem';
import type { PandoraNavItem } from '../scripts/elements/nav/pandora-nav-item';
import type { PandoraNav } from '../scripts/elements/nav/pandora-nav';

/**
 * Set `selected` state to matched location nav item
 * @param location href of nav item
 * @returns {void}
 */
export const setSelectedNavItem = (location: string) : void => {
  void window.customElements.whenDefined('pandora-nav-item').then(() => {
    const navItems = queryAll<PandoraNavItem>('pandora-nav-item');
    for (const navItem of navItems) {
      if (navItem.location === location) {
        navItem.selected = true;
      }
      else {
        navItem.selected = false;
      }
    }
  });
};

/**
 * convert menu to nav data
 * @param menu menu data
 * @returns menu as nav data
 */
const convertMenuToNavData = (menu: MenuItem[] = []) => {
  return menu.map(menuData => ({
    ...menuData,
    collapsed: true,
    collapsible: menuData.items && menuData.items.length > 0,
    isMenu: true
  }));
};

/**
 * Initialize nav from nav and menu data
 * @param nav nav data
 * @param menu menu data
 * @param uuid unique id of parser data
 * @returns {void}
 */
export const initNav = (nav: NavItem[], menu: MenuItem[], uuid: string) : void => {
  const navElement: PandoraNav = query('pandora-nav');
  const navData = [...nav, ...convertMenuToNavData(menu)];
  navElement.id = uuid;

  navElement.data = navData;
};
