import { LitElement, css, html, PropertyValues, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';

/**
 * Code editor console message
 */
@customElement('live-console-message')
export class LiveConsoleMessage extends LitElement {
  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14rem;
      min-height: 25px;
      padding: 5px 35px;
      border-bottom: 1px solid var(--separator-color);
    }
    :host [part=container]::marker {
      font-size: 10px;
    }
    :host([expandable]) [part=container] {
      cursor: pointer;
      display: list-item;
      list-style: inside disclosure-closed;
    }
    :host([opened][expandable]) [part=container] {
      list-style-type: disclosure-open;
    }
    :host([type=error]) {
      color: #FAA8B1;
      background-color: #A01C2B;
    }
    :host([type=warn]) {
      color: #FFE999;
      background-color: #7F6400;
    }
    :host [part=stack-trace] {
      opacity: 0.8;
      margin-left: 20px;
    }
  `;

  @property({ type: String, reflect: true })
  public type = '';

  @property({ type: String })
  public stackTrace = '';

  @property({ type: Boolean, reflect: true })
  public opened = false;

  /**
   * Called when connected to DOM
   * @returns {void}
   */
  connectedCallback (): void {
    super.connectedCallback();
    this.addEventListener('click', this.toggleOpened);
  }

  /**
   * Called when the element’s DOM has been updated and rendered
   * @param changedProperties Properties that has changed
   * @returns {void}
   */
  updated (changedProperties: PropertyValues): void {
    super.updated(changedProperties);
    if (changedProperties.has('stackTrace')) {
      if (this.stackTrace) {
        this.setAttribute('expandable', '');
      }
      else {
        this.removeAttribute('expandable');
      }
    }
  }

  /**
   * Toggle opened state of stack trace
   * @returns {void}
   */
  private toggleOpened = (): void => {
    if (this.stackTrace) {
      this.opened = !this.opened;
    }
  }

  render (): TemplateResult {
    return html`
      <div part="container">
        <slot></slot>
      </div>
      ${this.opened ? html`<div part="stack-trace">${this.stackTrace}</div>` : null}
    `;
  }
}
