import { LitElement, css, html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';

type LanguageItem = {
  label: string,
  value: string
}

/**
 * Language's selector - use to switching language of tab or select element
 */
@customElement('language-selector')
export class LanguageSelector extends LitElement {
  static styles = css`
    :host {
      display: flex;
      z-index: 1;
      width: 100%;
      background-color: var(--primary-bg-color);
      border-bottom: 1px solid var(--separator-color);
    }
    :host [part=lang-tab] {
      position: relative;
      cursor: pointer;
      display: inline-block;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 500;
      padding: 10px 15px;
      color: var(--primary-text-color);
      background-color: var(--primary-bg-color);
      font-size: 14rem;
    }
    :host [part=lang-tab]:hover {
      opacity: 0.8;
    }
    :host [part=lang-tab][active] {
      background-color: var(--pre-bg-color);
    }
    :host [part=lang-tab][active]::after {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      left: 0;
      bottom: -1px;
      background-color: var(--pre-bg-color);
    }
  `;

  @property({ type: Array })
  languages: LanguageItem[] = [];

  @property({ type: String })
  value = '';

  /**
   * Change active language handler
   * @param activeLang active language
   * @returns {void}
   */
  private handleChangeLanguage (activeLang: string): void {
    if (this.value !== activeLang) {
      this.value = activeLang;
      this.dispatchEvent(new CustomEvent('value-changed', {
        detail: {
          value: activeLang
        }
      }));
    }
  }

  render () : TemplateResult {
    if (this.languages.length < 1) {
      return html``;
    }
    return html`
      ${this.languages.map((lang) =>
        html`<a part="lang-tab" ?active="${this.value === lang.value}" @click="${() => this.handleChangeLanguage(lang.value)}">${lang.label}</a>`
      )}
    `;
  }
}
