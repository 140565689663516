/**
 * Normalises a base url string to make it compatible with PageJS
 * @param url URL string to normalise
 * @returns A normalised version of the url
 */
export const normaliseBaseUrl = (url: string): string => url.replace(/\/$/, '');

/**
 * Normalises a route url string to make it compatible with PageJS
 * @param url URL string to normalise
 * @returns A normalised version of the url
 */
export const normaliseRouteUrl = (url: string): string => url.replace(/^(\.?\/)?/, '/');
