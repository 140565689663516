import { LitElement, html, css, TemplateResult, CSSResultGroup, PropertyValues } from 'lit';
import { customElement, query } from 'lit/decorators.js';
import { buildIframeDoc } from '../../utils/iframe';
import { overrideConsole } from '../../utils/console';

import { editSandbox } from './live-code';

import type { CodeSnippet } from './type';
import type { IsomorphicWindow } from '../../interfaces/IsomorphicWindow';

const secureIframes = new WeakSet<Element>();
const documentElements = new WeakMap<Element, HTMLElement>();
const activeSandboxes = new Set<CodeSandbox>();

/**
 * Handler to set resize observer to the iframe
 * @returns {void}
 */
const ro = new ResizeObserver((entries) => {
  for (const entry of entries) {
    const { target, contentRect } = entry;
    const iframe = target.ownerDocument.defaultView?.frameElement as HTMLElement;
    if (iframe) {
      iframe.style.height = `${contentRect.height}px`;
    }
  }
});

/**
 * Mutation Observer for watching theme changes
 */
const mo = new MutationObserver(() => activeSandboxes.forEach(sandbox => sandbox.reload()));

/**
 * Observe body theme attribute
 */
mo.observe(document.body, {
  attributes: true,
  attributeFilter: ['color-scheme']
});

/**
 * Handles the initialization of the sandbox
 * @param scope document of an iframe
 * @returns {void}
 */
const initSandbox = (scope: IsomorphicWindow) => {
  const frame = scope.frameElement as HTMLIFrameElement;
  const oldEl = documentElements.get(frame);
  const newEl = scope.document.documentElement;
  if (oldEl) {
    ro.unobserve(oldEl);
  }
  if (newEl) {
    documentElements.set(frame, newEl);
    ro.observe(newEl);
  }
  overrideConsole(scope);
};

/**
 * Checks to see if the source being passed in known and secure
 * @param source Source to check
 * @returns If the source is secure or not
 */
const isSecureSource = (source: unknown): source is IsomorphicWindow => {
  const frameElement = !!source && (source as Window).frameElement;
  return !!frameElement && secureIframes.has(frameElement);
};

/**
 * Listen to messages from sandbox iframe
 */
window.addEventListener('message', function (event) {
  if (isSecureSource(event.source)) {
    switch (event.data) {
      case 'init-sandbox':
        return initSandbox(event.source);
      default:
        return;
    }
  }
});

/**
 * code-sandbox - a demo block on document page
 */
@customElement('code-sandbox')
export class CodeSandbox extends LitElement {

  @query('iframe')
  protected iframe! : HTMLIFrameElement;

  /**
   * A variable for storing code snippet that using in code sandbox
   */
  private _snippets: CodeSnippet[] = [];

  public get snippets () : CodeSnippet[] {
    return this._snippets.slice(); // prevent modification of the internal array
  }

  public set snippets (snippets : CodeSnippet[]) {
    this._snippets = snippets;
  }

  /**
   * Runs tasks depending on DOM connection state.
   * @returns {void}
   */
  private connectedStateChanged () {
    const documentElement = documentElements.get(this.iframe);
    if (this.isConnected) {
      documentElement && ro.observe(documentElement); // Observe dimension changes again
      activeSandboxes.add(this);
    }
    else {
      documentElement && ro.unobserve(documentElement); // Stop observing dimension changes
      activeSandboxes.delete(this);
    }
  }

  /**
   * Handler to collect programing language in pre > code block
   * it will take code block inside the slot and storing programing language into variable
   * @returns {void}
   */
  private collectLanguages () : void {
    const codeBlocks = this.querySelectorAll('pre code');
    this._snippets = [];
    for (const codeBlock of codeBlocks) {
      if (codeBlock.classList[0].indexOf('language-') === 0) {
        const snippet = { language: codeBlock.classList[0].replace('language-', ''), snippet: codeBlock.textContent || '' };
        if (snippet.language === 'javascript') {
          snippet.language = 'js';
        }
        this._snippets.push(snippet);
      }
    }
  }

  /**
   * Building document source for rendering it in the iframe
   * @returns {void}
   */
  private renderIframe () : void {
    const html = this._snippets.find(value => value.language === 'html')?.snippet || '';
    const css = this._snippets.find(value => value.language === 'css')?.snippet || '';
    const js = this._snippets.find(value => value.language === 'js')?.snippet || '';
    this.iframe.srcdoc = buildIframeDoc(html, css, js);
  }

  /**
   * Reloading sandbox to be updated
   * @returns {void}
   */
  public reload () : void {
    this.renderIframe();
  }

  /**
   * Handler to open live code editor
   * it will passing the programming languages to the live code component and display live code editor
   * @returns {void}
   */
  private handleLiveCode () : void {
    editSandbox(this);
  }

  private reset () : void {
    this.collectLanguages();
    this.reload();
  }

  /**
     * A `CSSResult` that will be used
     * to style the host, slotted children
     * and the internal template of the element.
     * @return CSS template
     */
  static get styles () : CSSResultGroup {
    return css`
      :host {
        display: block;
        overflow: hidden;
      }
      iframe {
        min-height: 100%;
        max-height: 100%;
        background-color: var(--iframe-bg-color);
        display: block;
        width: 100%;
        border: none;
        border-radius: 3px;
        transition: height 200ms ease 0s;
      }
      svg {
        width: 100%;
        fill: var(--primary-text-color);
      }
      #toolbar {
        display: flex;
        justify-content: flex-end;
        margin-top: 10rem;
      }
      button {
        cursor: pointer;
        color: var(--link-text-color);
        border: none;
        background: transparent;
        display: flex;
        width: 35rem;
        height: 35rem;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        box-sizing: border-box;
        padding: 3px;
      }
      button:hover {
        background-color: var(--separator-color);
      }
      #button-reset {
        padding: 8rem;
      }
    `;
  }

  /**
   * @override
   */
  public connectedCallback () : void {
    super.connectedCallback();
    this.connectedStateChanged();
  }

  /**
   * @override
   */
  public disconnectedCallback () : void {
    super.disconnectedCallback();
    this.connectedStateChanged();
  }

  /**
   * @override
   */
  protected firstUpdated (changedProperties: PropertyValues) : void {
    super.firstUpdated(changedProperties);
    this.collectLanguages();
    this.renderIframe();
    secureIframes.add(this.iframe);
  }

  /**
   * render this component
   * @returns the main template
   */
  protected render () : TemplateResult {
    return html`
      <iframe sandbox="allow-scripts allow-same-origin"></iframe>
      <div id="toolbar">
        <button id="button-edit" @click="${this.handleLiveCode}">
          <svg viewBox="0 0 128 128">
            <path d="m40.3 85.35a3.988 3.988 0 0 1 -2.828-1.172l-17.348-17.346a4 4 0 0 1 0-5.658l17.346-17.346a4 4 0 1 1 5.657 5.657l-14.518 14.515 14.518 14.521a4 4 0 0 1 -2.827 6.829z" />
            <path d="m87.7 85.345a4 4 0 0 1 -2.829-6.829l14.52-14.516-14.518-14.52a4 4 0 0 1 5.657-5.657l17.346 17.347a4 4 0 0 1 0 5.657l-17.346 17.347a3.991 3.991 0 0 1 -2.83 1.171z" />
            <path d="m56 90.5a4 4 0 0 1 -3.771-5.333l15.9-45a4 4 0 1 1 7.543 2.665l-15.9 45a4 4 0 0 1 -3.772 2.668z" />
          </svg>
        </button>
        <button id="button-reset" @click="${this.reset}">
          <svg viewBox="0 0 24 24" aria-hidden="true">
            <path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"></path>
          </svg>
        </button>
      </div>
    `;
  }
}
