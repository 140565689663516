import { LitElement, css, html, PropertyValues, TemplateResult } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import './live-console-message';

import { OverrideErrorEvent, OverrideConsoleEvent } from '../../interfaces/OverrideEvent';
import type { LiveConsoleMessage } from './live-console-message';

const SUPPORTED_CONSOLE = ['log', 'info', 'warn', 'error'];

// Define override console/error events for global scope
declare global {
  interface GlobalEventHandlersEventMap {
    'override-error': OverrideErrorEvent;
    'override-console': OverrideConsoleEvent;
  }
}

/**
 * Code editor console
 */
@customElement('live-console')
export class LiveConsole extends LitElement {
  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-height: 35px;
      background-color: var(--third-column-bg-color);
    }
    :host([opened]) {
      max-height: 100%;
      min-height: calc(35px + 20%);
    }
    svg {
      width: 100%;
      height: 100%;
    }
    button {
      cursor: pointer;
      display: flex;
      align-items: center;
      background-color: transparent;
      outline: none;
      border: none;
      color: var(--primary-text-color);
    }
    button:hover {
      color: var(--highlight-text-color);
      background-color: var(--hover-bg-color);
    }
    button:active {
      background-color: var(--active-bg-color);
    }
    :host [part=header] {
      height: 35px;
      display: flex;
      justify-content: space-between;
      background-color: var(--primary-bg-color);
      border-bottom: 1px solid var(--separator-color);
    }
    :host [part=header-tab] {
      padding: 5px 20px;
    }
    :host [part=counter] {
      font-size: 10rem;
      border-radius: 50%;
      padding: 5px 3px;
      min-width: 15px;
      margin-left: 10px;
      background-color: var(--pre-bg-color);
    }
    :host [part=toolbar] {
      display: flex;
      align-items: center;
      margin-right: 10px;
      padding: 5px 0;
    }
    :host [part=clear-button], :host [part=show-browser-button], :host [part=collapsed-button] {
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }
    :host [part=show-browser-button][active], :host([opened]) [part=header-tab] {
      color: var(--text-highlight-color);
      background-color: var(--active-bg-color);
    }
    :host([opened]) [part=collapsed-button] {
      color: var(--text-highlight-color);
      transform: rotate(180deg);
    }
    :host [part=message-container] {
      flex: 1 1 auto;
      overflow-y: auto;
      height: 0;
    }
    :host [part=default-message] {
      font-style: italic;
      opacity: 0.7;
      font-size: 14rem;
      padding: 5px 35px;
      border-bottom: 1px solid var(--separator-color);
    }
    ::-webkit-scrollbar {
      width: 5rem;
      height: 5rem;
    }
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px var(--hover-bg-color);
    }
    ::-webkit-scrollbar-thumb {
      background-color: var(--scrollbar-thumb-color);
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: var(--scrollbar-hover-color);
    }
  `;

  @query('[part=message-container]')
  protected messageContainer! : HTMLElement;

  @query('[part=show-browser-button]')
  protected showBrowserButton! : HTMLElement;

  @property({ type: Boolean, reflect: true })
  public opened = false;

  @property({ type: Number })
  public messageCount = 0;

  @property({ type: Boolean })
  public showBrowserConsole = true;

  constructor () {
    super();
    this.updateErrorLog = this.updateErrorLog.bind(this);
    this.updateConsoleLog = this.updateConsoleLog.bind(this);
  }

  /**
   * Called when connected to DOM
   * @returns {void}
   */
  connectedCallback (): void {
    super.connectedCallback();
    window.addEventListener('override-error', this.updateErrorLog);
    window.addEventListener('override-console', this.updateConsoleLog);
  }

  /**
   * Called when disconnected from DOM
   * @returns {void}
   */
  disconnectedCallback (): void {
    super.disconnectedCallback();
    window.removeEventListener('override-error', this.updateErrorLog);
    window.removeEventListener('override-console', this.updateConsoleLog);
  }

  /**
   * On first updated lifecycle
   * @param changedProperties changed property
   * @returns {void}
   */
  protected firstUpdated (changedProperties: PropertyValues): void {
    super.firstUpdated(changedProperties);
    this.setConsoleDefaultMessage();
  }

  /**
   * Reset all message from console
   * @returns {void}
   */
  public clear (): void {
    if (this.messageContainer) {
      this.messageContainer.innerHTML = '';
    }
    this.messageCount = 0;
    this.setConsoleDefaultMessage();
  }

  /**
   * Update error message in console
   * @param event error event
   * @returns {void}
   */
  private updateErrorLog (event: OverrideErrorEvent) {
    if (!this.showBrowserConsole) {
      event.preventDefault();
    }
    const { msg, url, lineNo } = event.detail;
    const stackTrace = url ? url + (lineNo ? `:${lineNo}` : '') : '';
    this.updateConsoleMessage(msg.toString(), stackTrace, 'error');
  }

  /**
   * Update error message in console
   * @param event error event
   * @returns {void}
   */
  private updateConsoleLog (event: OverrideConsoleEvent): void {
    if (!this.showBrowserConsole) {
      event.preventDefault();
    }

    const { msg } = event.detail;
    let { key } = event.detail;
    let newMsg = '';

    if (SUPPORTED_CONSOLE.indexOf(key) < 0) {
      key = 'warn';
      newMsg = 'Unsupported console command. To show this output, enable the browser console.';
    }
    else if (Array.isArray(msg)) {
      msg.forEach(m => {
        newMsg += this.parseConsoleMessage(m);
      });
    }
    else {
      newMsg = this.parseConsoleMessage(msg);
    }
 
    this.updateConsoleMessage(newMsg, '', key);
  }

  /**
   * Parse console message to string
   * @param msg console message
   * @returns {void}
   */
  private parseConsoleMessage (msg: unknown): string {
    if (typeof msg === 'number' || typeof msg === 'function' || msg instanceof Date) {
      return msg.toString();
    }
    else {
      try {
        return '\n' + JSON.stringify(msg, null, 2);
      }
      catch (error) {
        return ' ' + '[Object circular]';
      }
    }
  }

  /**
   * Append live-console-message element to message container
   * @param message console message
   * @param stackTrace stack trace of error
   * @param type type of message
   * @returns {void}
   */
  private updateConsoleMessage (message: string, stackTrace: string, type: string): void {
    const errorMsgItem = document.createElement('live-console-message') as LiveConsoleMessage;
    errorMsgItem.textContent = message;
    errorMsgItem.stackTrace = stackTrace;
    errorMsgItem.type = type;
    this.messageContainer.appendChild(errorMsgItem);
    this.messageCount++;
  }

  /**
   * Set default message of console on first load
   * @returns {void}
   */
  private setConsoleDefaultMessage (): void {
    const defaultMessage = document.createElement('div');
    defaultMessage.textContent = 'Console was cleared';
    defaultMessage.setAttribute('part', 'default-message');
    if (this.messageContainer) {
      this.messageContainer.appendChild(defaultMessage);
    }
  }

  /**
   * Toggle opened state
   * @returns {void}
   */
  private toggleOpened (): void {
    this.opened = !this.opened;
  }

  /**
   * Toggle show browser console state
   * @returns {void}
   */
  private toggleShowBrowserConsole (): void {
    this.showBrowserButton.toggleAttribute('active');
    this.showBrowserConsole = !this.showBrowserConsole;
  }

  render (): TemplateResult {
    return html`
      <div part="header">
        <button part="header-tab" @click="${this.toggleOpened}">
          Console
          <div part="counter">${this.messageCount}</div>
        </button>
        <div part="toolbar">
          <button title="Show log on browser console." part="show-browser-button" @click="${this.toggleShowBrowserConsole}">
            <svg fill="currentColor" viewBox="0 0 24 24">
              <path d="M 3 3 L 3 7 L 3 9 L 3 21 L 9 21 L 9 19 L 5 19 L 5 9 L 19 9 L 19 19 L 15 19 L 15 21 L 21 21 L 21 9 L 21 7 L 21 3 L 3 3 z M 6 5 C 6.552 5 7 5.448 7 6 C 7 6.552 6.552 7 6 7 C 5.448 7 5 6.552 5 6 C 5 5.448 5.448 5 6 5 z M 9 5 C 9.552 5 10 5.448 10 6 C 10 6.552 9.552 7 9 7 C 8.448 7 8 6.552 8 6 C 8 5.448 8.448 5 9 5 z M 12 5 L 19 5 L 19 7 L 12 7 L 12 5 z M 12 11 L 8 15 L 11 15 L 11 21 L 13 21 L 13 15 L 16 15 L 12 11 z"></path>
            </svg>
          </button>
          <button title="Clear console." part="clear-button" @click="${this.clear}">
            <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" viewBox="0 0 40 40">
              <g>
                <path d="m20 33.4c7.3 0 13.4-6.1 13.4-13.4 0-3-1.1-5.9-2.9-8.2l-18.7 18.7c2.3 1.8 5.2 2.9 8.2 2.9z m-13.4-13.4c0 3 1.1 5.9 2.9 8.2l18.7-18.7c-2.3-1.8-5.2-2.9-8.2-2.9-7.3 0-13.4 6.1-13.4 13.4z m13.4-16.6c9.2 0 16.6 7.4 16.6 16.6s-7.4 16.6-16.6 16.6-16.6-7.4-16.6-16.6 7.4-16.6 16.6-16.6z">
                </path>
              </g>
            </svg>
          </button>
          <button part="collapsed-button" @click="${this.toggleOpened}">
            <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" viewBox="0 0 40 40">
              <g>
                <path d="m31 26.4q0 0.3-0.2 0.5l-1.1 1.2q-0.3 0.2-0.6 0.2t-0.5-0.2l-8.7-8.8-8.8 8.8q-0.2 0.2-0.5 0.2t-0.5-0.2l-1.2-1.2q-0.2-0.2-0.2-0.5t0.2-0.5l10.4-10.4q0.3-0.2 0.6-0.2t0.5 0.2l10.4 10.4q0.2 0.2 0.2 0.5z">
                </path>
              </g>
            </svg>
          </button>
        </div>
      </div>
      <div part="message-container"></div>
    `;
  }
}
