import { ContentMenu } from './elements/content-menu';
import { ContentMenuData } from './interfaces/ContentMenu';
import { query, queryAll } from './utils/query';

/**
 * Initialize content menu from header tags
 * @returns {void}
 */
export const initContentMenu = (): void => {
  const title = query('.content > h1');
  const contentHeaders = queryAll('.content > h2, h3');
  const contentMenu: ContentMenu = query('content-menu');
  const contentData: ContentMenuData[] = [];

  // Reset content-menu title and data when routes changed
  contentMenu.title = '';
  contentMenu.data = [];

  if (title) {
    contentMenu.menuTitle = {
      label: title.textContent || '',
      id: title.id
    };
    
    for (let i = 0; i < contentHeaders.length; i++) {
      const header = contentHeaders[i];
      const headerData = {
        id: header.id,
        label: header.textContent || '',
        children: []
      };
      if (header.tagName === 'H2') {
        contentData.push({ ...headerData, tag: 'H2' });
      }
      else if (header.tagName === 'H3' && (contentData.length <= 0 || contentData[contentData.length - 1].tag !== 'H2')) {
        contentData.push({ ...headerData, tag: 'H3' });
      }
      else if (header.tagName === 'H3' && contentData.length > 0 && contentData[contentData.length - 1].tag === 'H2') {
        contentData[contentData.length - 1].children.push(headerData);
      }
    }
    contentMenu.data = contentData;
  }
};
