import './settings';
import './theme';
import './sidebar';
import './elements/nav/pandora-nav';
import './elements/nav/pandora-nav-item';
import './elements/codeBlock/code-sandbox';
import './elements/codeBlock/live-code';
import './elements/codeBlock/live-editor';
import './elements/language-selector';
import './elements/content-menu';

import { initHeader } from './header';
import { initNav } from './nav';
import { initRoutes } from './routes';
import { initSearchIndex } from './search';
import { data as mockData } from './mockupData/book';

import type { ParsedResult } from '@pandora/parser/src/interfaces/ParsedResult';

declare global {
  interface Window {
    initBook: (data: ParsedResult) => void;
  }
}

/**
 * Initialize the view with all routes from book data
 * @param data book data
 * @returns {void}
 */
window.initBook = (data): void => {
  const { nav, menu, routes, uuid, title, logo } = data;
  initHeader(title, menu, logo);
  initNav(nav, menu, uuid);
  initRoutes(data);
  initSearchIndex(routes);
};

/**
 * Setup demo data
 */
if (process.env.NODE_ENV === 'development') {
  const baseTag = document.createElement('base');
  baseTag.href = mockData.base;
  document.head.appendChild(baseTag);
  window.initBook(mockData as ParsedResult);
}
