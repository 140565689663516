import { MenuItem } from '@pandora/parser/src/interfaces/MenuItem';
import { show } from './utils/visibility';
import { query, queryAll } from './utils/query';

const DEFAULT_LOGO_SRC = '../assets/images/pandora-logo.png';

/**
 * Create dropdown menu
 * @param menu menu data
 * @returns dropdown menu template
 */
const createDropdownMenu = (menu: MenuItem) => {
  const dropdownMenu = document.createElement('div');
  dropdownMenu.className = 'dropdown';

  const dropdownBtn = document.createElement('button');
  dropdownBtn.className = 'dropdown-btn';
  dropdownBtn.textContent = menu.title;

  const dropdownArrow = document.createElement('div');
  dropdownArrow.className = 'dropdown-arrow';

  const dropdownContent = document.createElement('div');
  dropdownContent.className = 'dropdown-content';

  menu.items?.forEach(item => {
    dropdownContent.appendChild(createMenuLink(item));
  });

  dropdownMenu.appendChild(dropdownBtn);
  dropdownBtn.appendChild(dropdownArrow);
  dropdownMenu.appendChild(dropdownContent);

  return dropdownMenu;
};

/**
 * Create menu link
 * @param menu menu data
 * @returns menu link template
 */
const createMenuLink = (menu: MenuItem) => {
  const menuLink = document.createElement('a');
  menuLink.textContent = menu.title;
  if (menu.location) {
    menuLink.href = menu.location;
  }
  return menuLink;
};

/**
 * Initialize header menu
 * @param menu menu data
 * @returns {void}
 */
const initHeaderMenu = (menu: MenuItem[]) => {
  const headerMenu = query('.header-menu');

  if (!menu && headerMenu) {
    headerMenu.innerHTML = '';
  }

  const menuFragment = new DocumentFragment();

  menu.forEach((menuItem) => {
    const { items } = menuItem;
    const menuTemplate = items && items.length > 0 ? createDropdownMenu(menuItem) : createMenuLink(menuItem);
    menuFragment.appendChild(menuTemplate);
  });

  headerMenu?.appendChild(menuFragment);
};

/**
 * Initialize header
 * @param title header title
 * @param menu header menu
 * @param logo header logo
 * @returns {void}
 */
export const initHeader = (title: string, menu: MenuItem[], logo: string) : void => {
  show(query('.header-container'));
  const favicon = document.querySelector('link[rel~="icon"]');
  const headerTitles = queryAll('.header-title');
  const headerLogos: NodeListOf<HTMLImageElement> = queryAll('.header-logo');

  for (const headerTitle of headerTitles) {
    headerTitle.textContent = title;
  }

  for (const headerLogo of headerLogos) {
    if (logo) {
      headerLogo.src = logo;
      headerLogo.classList.add('invert-color');
    }
    else {
      headerLogo.src = DEFAULT_LOGO_SRC;
    }
  }

  if (!favicon) {
    const favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = logo || DEFAULT_LOGO_SRC;
    document.head.appendChild(favicon);
  }
  initHeaderMenu(menu);
};
