const VISIBILITY_CLASS = 'is-visible';

type ElementType = HTMLElement | Element | null | undefined;

/**
 * Show an element
 * @param element element to show
 * @param className visibility class default is `is-visible`
 * @returns {void}
 */
export const show = (element: ElementType, className: string = VISIBILITY_CLASS) : void => {
  if (element) {
    element.classList.add(className);
  }
};

/**
 * Hide an element
 * @param element element to hide
 * @param className visibility class default is `is-visible`
 * @returns {void}
 */
export const hide = (element: ElementType, className: string = VISIBILITY_CLASS): void => {
  if (element) {
    element.classList.remove(className);
  }
};

/**
 * Toggle element visibility
 * @param element element to change visibility
 * @param className visibility class default is `is-visible`
 * @returns {void}
 */
export const toggle = (element: ElementType, className: string = VISIBILITY_CLASS): void => {
  if (!element) {
    return;
  }
  element.classList.toggle(className);
};

