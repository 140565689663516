/**
 * On click outside of target element handler
 * @param element element target
 * @param callback callback function
 * @returns {void}
 */
export const onOutsideClick = (element: HTMLElement, callback: () => void): void => {
  if (!element) {
    return;
  }
  window.addEventListener('mousedown', (event: MouseEvent) => {
    const target = event.target as Element;
    if (!element.contains(target)) {
      callback();
    }
  });
};
