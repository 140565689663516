/**
 * Building Iframe document
 * @param body adding html structure in to use iframe
 * @param style adding styles to use in iframe
 * @param script adding scripts to use in iframe
 * @return Iframe document source
 */
export const buildIframeDoc = (body: string, style: string, script: string) : string => {
  const scheme = document.body.getAttribute('color-scheme') || '';
  return `
  <!DOCTYPE html>
  <html prefers-color-scheme="${scheme}">
  <head>
    <style>
      /* Default Styles */
      html {
        overflow: hidden;
        background-color: transparent;
      }
      *:not(:defined) {
        display: none;
      }
      /* Injected Styles */
      ${style}
    </style>
  </head>
  <body>
    <script>parent.postMessage('init-sandbox')</script>
    ${body}
    <script type="module">
      ${script}
    </script>
  </body>
  </html>`;
};
