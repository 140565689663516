/**
 * Debounced function is delays invoking the provided function
 * until at least ms milliseconds have elapsed
 * @param fn a function to apply to debounce
 * @param ms a period of time before function execute
 * @return void
 */
export const debounce = <F extends (...params: unknown[]) => void>(fn: F, ms: number): F => {
  let timeoutID: number;
  return function (this: unknown, ...args: unknown[]) {
    clearTimeout(timeoutID);
    timeoutID = window.setTimeout(() => fn.apply(this, args), ms);
  } as F;
};
