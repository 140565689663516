import { LitElement, css, html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import './panel-overlay';
import type { PanelOverlay } from './panel-overlay';

const overlay = document.createElement('panel-overlay') as PanelOverlay; // reuseable overlay element

/**
 * Panel resizer
 */
@customElement('panel-dragger')
export class PanelDragger extends LitElement {
  static styles = css`
    :host {
      display: none;
      position: relative;
      flex: 0 0 4px;
      background: var(--separator-color);
      z-index: 10;
    }
    :host::after {
      content: '';
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: -2px;
      right: -2px;
    }
    :host([vertical]) {
      cursor: row-resize;
    }
    :host([horizontal]) {
      cursor: col-resize;
    }
  `;

  /**
   * Session key of panel sizes data
   */
  @property({ type: String })
  public sessionKey = '';

  private enabledDragging = false;
  private firstPanel: HTMLElement | null = null;
  private secondPanel: HTMLElement | null = null;

  constructor () {
    super();
    this.initDrag = this.initDrag.bind(this);
    this.onDrag = this.onDrag.bind(this);
    this.stopDrag = this.stopDrag.bind(this);
  }

  /**
   * Called when connected to DOM
   * @returns {void}
   */
  public connectedCallback () : void {
    super.connectedCallback();
    this.addEventListener('touchstart', this.initDrag);
    this.addEventListener('mousedown', this.initDrag);
    this.firstPanel = this.previousElementSibling as HTMLElement;
    this.secondPanel = this.nextElementSibling as HTMLElement;

    // TODO (Trem): Discuss this with Mos
    if (!this.shadowRoot?.contains(overlay)) {
      this.shadowRoot?.appendChild(overlay);
    }
  }

  /**
   * handle mousedown event when click on the dragger
   * @param event mouse event or touch event
   * @returns {void}
   */
  private initDrag (event: MouseEvent | TouchEvent): void {
    event.stopPropagation();
    this.enabledDragging = true;
    overlay.opened = true;
    document.addEventListener('touchmove', this.onDrag);
    document.addEventListener('touchend', this.stopDrag);
    document.addEventListener('mousemove', this.onDrag);
    document.addEventListener('mouseup', this.stopDrag);
    document.addEventListener('mouseleave', this.stopDrag);
  }

  /**
   * Calculate the panel size on dragging
   * @param event mouse event or touch event
   * @returns {void}
   */
  private calculatePanelSize (event: MouseEvent | TouchEvent) {
    let size = 0;
    const rect = this.parentElement?.getBoundingClientRect();
    const position = event.type === 'touchmove' ? (event as TouchEvent).changedTouches[0] : event as MouseEvent;

    if (this.parentElement) {
      if (this.offsetTop !== rect?.top) {
        const startingY = rect?.top || 0;
        size = ((position.clientY - startingY) / this.parentElement.clientHeight);
      }
      else {
        size = position.clientX / this.parentElement.clientWidth;
      }
    }

    return Math.max(size * 100, 0);
  }

  /**
   * handle on drag event of document
   * @param event mouse event or touch event
   * @returns {void}
   */
  private onDrag (event: MouseEvent | TouchEvent): void {
    if (!this.enabledDragging || !this.firstPanel || !this.secondPanel) {
      return;
    }
    const newSize = this.calculatePanelSize(event);
    this.firstPanel.style.flexBasis = `${newSize}%`;
    this.secondPanel.style.flexBasis = `${100 - newSize}%`;
  }

  /**
   * handle stop drag event of document
   * @returns {void}
   */
  private stopDrag (): void {
    this.enabledDragging = false;
    overlay.opened = false;
    if (this.sessionKey && this.firstPanel && this.secondPanel) {
      const panelSizes = [this.firstPanel.style.flexBasis, this.secondPanel.style.flexBasis];
      sessionStorage.setItem(this.sessionKey, panelSizes.toString());
    }
    document.removeEventListener('touchmove', this.onDrag);
    document.removeEventListener('mousemove', this.onDrag);
    document.removeEventListener('touchend', this.stopDrag);
    document.removeEventListener('mouseup', this.stopDrag);
    document.removeEventListener('mouseleave', this.stopDrag);
  }

  render () : TemplateResult {
    return html``;
  }
}
