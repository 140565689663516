import page from 'page';
import hljs from 'highlight.js';

import { setSelectedNavItem } from './nav';
import { initLanguageTabBar } from './languageSelector';
import { initContentMenu } from './contentMenu';

import { hide } from './utils/visibility';
import { query, queryAll } from './utils/query';

import type { ParsedResult } from '@pandora/parser/src/interfaces/ParsedResult';
import { normaliseBaseUrl, normaliseRouteUrl } from './utils/url';
import { ContentMenu } from './elements/content-menu';

const PAGE_NOT_FOUND_TEMPLATE = `
  <h1>Page not found</h1>
  <h4>Sorry, we can\'t find the page you are looking for.</h4>
  <p>It looks like this was the result of either:</p>
  <ul>
    <li>A mistyped address</li>
    <li>An out-of-date bookmark in your web browser</li>
    <li>A broken link on our site</li>
    <li>A broken link on a search engine results page</li>
    <li>A broken link on someone else's page</li>
  </ul>
`;

const MIN_SCREEN_HEIGHT = 500;

/**
 * Initialize default routes based on routes data
 * @param book book data
 * @returns {void}
 */
export const initRoutes = (book: ParsedResult) : void => {
  const { base, routes = [] } = book;
  const content = query('article .content');
  const container = query('.container');
  let userScroll = false; // use to preventing scroll event trigger

  page.base(normaliseBaseUrl(base));

  routes.forEach(route => page(normaliseRouteUrl(route.location), (ctx) => {
    const { hash } = ctx;

    // define basic info
    document.title = route.title;
    query('meta[name=description]').setAttribute('content', route.description);
    document.body.setAttribute('mode', route.layout);

    // close mobile sidebar when page changed
    hide(query('.backdrop-overlay'));
    hide(query('.sidebar'));

    // scroll to top when page changed
    container.scrollTop = 0;
    // reset content when page changed
    content.innerHTML = route.html;

    initLanguageTabBar(route.language_tabs);
    setSelectedNavItem(route.location);
    initContentMenu();
    
    hljs.highlightAll();

    const contentMenu: ContentMenu = query('content-menu');
    const contentHeaders = queryAll('.content > h2, h3');

    if (hash) {
      userScroll = false;
      container.scrollTop = query(`.content > [id="${hash}"]`).offsetTop;
      contentMenu.value = hash;
    }
    // Set first content menu to active if first header offset top is lower than min screen height
    else if (contentHeaders.length > 0 && contentHeaders[0].offsetTop <= MIN_SCREEN_HEIGHT) {
      contentMenu.value = contentHeaders[0].id;
    }

    // Detect user scroll to active content menu
    container.addEventListener('scroll', (e: Event) => {
      const target = e.target as HTMLElement;
      if (userScroll && target) {
        const scrollOffset = target.scrollTop;
        contentMenu.value = '';
        for (const header of contentHeaders) {
          if (scrollOffset + 80 <= contentHeaders[0].offsetTop) {
            contentMenu.value = contentHeaders[0].id;
          }
          else if (container.offsetHeight + scrollOffset === container.scrollHeight) {
            contentMenu.value = contentHeaders[contentHeaders.length - 1].id;
          }
          else if (header.offsetTop <= scrollOffset + 80) {
            contentMenu.value = header.id;
          }
        }
      }
    });
  }));

  // Not found route
  page('*', () => {
    if (content) {
      content.innerHTML = PAGE_NOT_FOUND_TEMPLATE;
    }
  });

  page({
    click: false
  });

  /**
   * Internal helper to test whether object is an instance of a HTMLElement.
   * @param element Target to test
   * @returns True if element is an anchor element
   */
  const isAnchorElement = (element: unknown): element is HTMLAnchorElement => element instanceof HTMLAnchorElement;

  /**
   * Custom click handler for navigation.
   * Adds support for custom elements.
   */
  document.addEventListener('click', (event) => {
    const anchorElement = event.composedPath().find(isAnchorElement);
    if (anchorElement && anchorElement.href) {
      const url = new URL(anchorElement.href);
      if (url.origin === location.origin) {
        event.preventDefault();
        userScroll = false;
        page(url.pathname + url.hash);
      }
    }
  }, true);

  // Detect whether scroll event was created by user
  document.addEventListener('DOMMouseScroll', () => {
    userScroll = true;
  });

  document.addEventListener('wheel', () => {
    userScroll = true;
  });

  document.addEventListener('keydown', (event: KeyboardEvent) => {
    if (event.code === 'ArrowUp'
    || event.code === 'ArrowDown'
    || event.code === 'PageUp'
    || event.code === 'PageDown'
    || event.ctrlKey && event.code === 'Home'
    || event.ctrlKey && event.code === 'End') {
      userScroll = true;
    }
  });
};
