import { LitElement, css, html, PropertyValues, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { getExpandedItemIds, setExpandedItemIds, getExpandedItemSessionData } from './helpers';

import type { PandoraNavItem } from './pandora-nav-item';
import type { NavItem } from '@pandora/parser/src/interfaces/NavItem';

export interface NavWithMenuItem extends NavItem {
  isMenu?: boolean
}

/**
 * pandora-nav-item's container
 */
@customElement('pandora-nav')
export class PandoraNav extends LitElement {
  static styles = css`
    :host {
      cursor: pointer;
      overflow-x: hidden;
      overflow-y: auto;
      display: block;
      flex: 1 1 100%;
      padding: 15rem;
      list-style: none;
      user-select: none;
      margin: 0;
    }
  `;

  @property({ type: Array })
  data: NavWithMenuItem[] = [];

  // running id of pandora-nav-item used to persist expanded state
  private runningId = 0;

  // used to check if session data has been initialized
  private expandedItemSessionData: string | null = null;

  // expanded item ids that stored in session storage
  private expandedItemIds: string[] = [];

  /**
   * Called when the element’s DOM has been updated and rendered
   * @param changedProperties Properties that has changed
   * @returns {void}
   */
  updated (changedProperties: PropertyValues) : void {
    super.updated(changedProperties);
    if (changedProperties.has('data') && this.data.length) {
      this.expandedItemSessionData = getExpandedItemSessionData(this.id);
      this.expandedItemIds = getExpandedItemIds(this.id, this.expandedItemSessionData);
      this.appendChild(this.createNavItem(this.data));
      if (this.expandedItemIds) {
        setExpandedItemIds(this.id, this.expandedItemIds.join());
      }
    }
  }

  /**
   * Recursive create `pandora-nav-item` based on nav data
   * @param nav nav data
   * @returns nav template
   */
  private createNavItem (nav: NavWithMenuItem[]) {
    const fragment = new DocumentFragment();

    nav.forEach((navData: NavWithMenuItem) => {
      const { title, location, collapsed, collapsible, isMenu, items } = navData;

      const navItemElement = document.createElement('pandora-nav-item') as PandoraNavItem;
      const navItemId = `cid-${++this.runningId}`;

      navItemElement.title = title;
      navItemElement.id = navItemId;
      navItemElement.navId = this.id;

      if (isMenu) {
        navItemElement.setAttribute('type', 'menu');
      }
      if (location) {
        navItemElement.location = location;
      }
      navItemElement.collapsed = !!collapsed;
      navItemElement.collapsible = !!collapsible;

      if (collapsible) {
        const isCollapsed = !this.expandedItemIds.includes(navItemId);
        // override collapsed state if expanded item ids session has been initialize
        if (this.expandedItemSessionData !== null) {
          navItemElement.collapsed = isCollapsed;
        }
        // Initialize expanded item ids session data
        else if (!collapsed && isCollapsed) {
          this.expandedItemIds.push(navItemId);
        }
      }

      if (items && items.length) {
        const childrenNav = this.createNavItem(items);
        navItemElement.appendChild(childrenNav);
      }
      fragment.appendChild(navItemElement);
    });

    return fragment;
  }

  render () : TemplateResult {
    return html`<slot></slot>`;
  }
}
