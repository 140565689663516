import { IsomorphicWindow } from '../interfaces/IsomorphicWindow';

/**
 * Override sandbox errors log and console
 * @param scope scope to be override
 * @returns {void}
 */
export const overrideConsole = (scope: IsomorphicWindow): void => {
  // Override errors
  scope.onerror = function (msg, url, lineNo) {
    const event = new CustomEvent('override-error', {
      cancelable: true,
      detail: {
        msg,
        url,
        lineNo
      }
    });
    dispatchEvent(event);
    return !event.defaultPrevented;
  };
  // Override console
  Object.keys(scope.console).forEach((key) => {
    const desc = Object.getOwnPropertyDescriptor(scope.console, key);
    Object.defineProperty(scope.console, key, {});
    if (desc && typeof desc.value === 'function') {
      Object.defineProperty(scope.console, key, {
        value: function (...msg: unknown[]) {
          const event = new CustomEvent('override-console', {
            cancelable: true,
            detail: {
              key,
              msg
            }
          });
          dispatchEvent(event);
          if (event.defaultPrevented) {
            (desc.value as (...args: unknown[]) => void)(...msg);
          }
        }
      });
    }
  });
};
