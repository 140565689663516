/**
 * Queries the DOM for an element matching the selector.
 * Throws an error, if nothing is found.
 * @param selector CSS selector
 * @returns Matched element
 */
export const query = <E extends HTMLElement>(selector: string): E => {
  const el = document.querySelector<E>(selector);
  if (!el) {
    throw new Error(`Element not found (${selector})`);
  }
  return el;
};

/**
 * Queries the DOM for elements matching the selector.
 * @param selector CSS selector
 * @returns Node list of matched elements
 */
export const queryAll = <E extends HTMLElement>(selector: string): NodeListOf<E> => {
  return document.querySelectorAll(selector);
};
