import { LitElement, css, html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ContentMenuData, MenuTitle } from '../interfaces/ContentMenu';

/**
 * Content menu - show list of header as a navigation menu
 */
@customElement('content-menu')
export class ContentMenu extends LitElement {
  static styles = css`
    :host {
      font-size: 14px;
      width: 250px;
    }
    h1 {
      font-size: 18rem;
      font-weight: 300;
    }
    a {
      outline: none;
      text-decoration: none;
      color: var(--primary-text-color);
    }
    ol {
      padding-inline-start: 25px;
      margin-block-start: 0px;
      margin-block-end: 0px;
    }
    a:hover {
      color: var(--text-highlight-color);
    }
    :host [part=content-container] {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      margin-left: 5px;
      padding: 0 25px;
      border-left: 1px solid var(--separator-color);
    }
    :host [part=item] {
      display: block;
      position: relative;
      opacity: 0.8;
      margin-bottom: 10px;
    }
    :host [part=item][active] {
      font-weight: bold;
      color: var(--text-highlight-color);
    }
    :host [part=item][active]::before {
      content: '';
      width: 7px;
      height: 7px;
      position: absolute;
      top: 8px;
      left: -29px;
      background-color: var(--code-hl-number-color);
      transform: rotate(45deg);
    }
    :host ol > [part=item][active]::before {
      left: -54px;
    }
  `;

  @property({ type: Array })
  data: ContentMenuData[] = [];

  @property({ type: Object, reflect: true })
  menuTitle: MenuTitle = {};

  @property({ type: String })
  value = '';

  /**
   * Change active menu
   * @param id id of link
   * @returns {void}
   */
  private handleClickMenu (id: string): void {
    this.value = id;
  }

  private renderSubMenu (content: ContentMenuData) {
    if (content.children.length < 1) {
      return;
    }
    return content.children.map(subMenu => html`
      <a part="item"
        ?active="${this.value === subMenu.id}"
        href="${window.location.pathname}#${subMenu.id}"
        @click="${() => this.handleClickMenu(subMenu.id)}"
      >
        ${subMenu.label}
      </a>`
    );
  }

  private get ContentTemplate () {
    if (!this.data.length) {
      return html``;
    }

    return this.data.map(content => html`
      <a part="item"
        ?active="${this.value === content.id}"
        href="${window.location.pathname}#${content.id}"
        @click="${() => this.handleClickMenu(content.id)}"
      >
        ${content.label}
      </a>
      <ol>${this.renderSubMenu(content)}</ol>
      `
    );
  }

  render () : TemplateResult {
    if (!this.menuTitle.label || !this.data.length) {
      return html``;
    }
    return html`
      <h1><a href="${window.location.pathname}#${this.menuTitle.id}">Contents: ${this.menuTitle.label}</a></h1>
      <div part="content-container">${this.ContentTemplate}</div>
    `;
  }
}
