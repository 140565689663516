import Fuse from 'fuse.js';
import { query } from './utils/query';
import { show, hide, toggle } from './utils/visibility';
import { textHighlighter } from './utils/textHighlighter';

import type { SearchIndex } from './interfaces/SearchIndex';
import type { RouteItem } from '@pandora/parser/src/interfaces/RouteItem';

let FUSE_SEARCH_INDEX: Fuse<SearchIndex>;

const SEARCH_KEYS = {
  TITLE: 'title',
  CONTENT: 'text'
};

const SEARCH_CONFIGS = {
  fuseOptions: {
    keys: [{
      name: SEARCH_KEYS.TITLE,
      weight: 0.7 // give title higher values in search results
    }, {
      name: SEARCH_KEYS.CONTENT,
      weight: 0.3
    }],
    includeMatches: true,
    useExtendedSearch: true // allows advanced searching ex. ( `.keyword` -> include-match )
  },
  limitTextLength: 255, // limit max summary text length
  matchedStartOffset: 20 // distance between first matched and started text index
};

const searchContainer = query('.search-container');
const searchInput: HTMLInputElement = query('.search-input');
const searchButton = query('.search-button');
const resultsContainer = query('.results-container');
const resultsBody = query('.results-body');

const resultsPlaceholder = query('.results-placeholder');
const resultsErrorMessage = query('.results-errorMsg');

/**
 * Close search container and hide close button
 * @returns {void}
 */
const closeSearch = (): void => {
  hide(searchButton, 'close-button');
  hide(searchContainer);
  hide(resultsContainer);
};

/**
 * Append search results to a container
 * @param results search results
 * @param container - container of search results
 * @returns {void}
 */
const outputSearchResults = (results: Fuse.FuseResult<SearchIndex>[], container: HTMLElement | null) => {
  if (!container) {
    return;
  }

  results.forEach((resultItem: Fuse.FuseResult<SearchIndex>) => {
    const item = document.createElement('A') as HTMLAnchorElement;
    const location = resultItem.item.location;
    item.className = 'item-container';
    item.href = location;

    item.addEventListener('click', () => {
      searchInput.value = '';
      resultsBody.innerHTML = '';
      closeSearch();
    });

    const title = document.createElement('H4');
    const br = document.createElement('HR');
    const summaryText = document.createElement('p');

    if (!resultItem.matches) {
      return;
    }

    // matched results can be title or text content
    resultItem.matches.map((resultMatch: Fuse.FuseResultMatch)=> {
      const { indices, key, value = '' } = resultMatch;
      // highlight title
      if (key === SEARCH_KEYS.TITLE) {
        textHighlighter(value, indices, title, SEARCH_CONFIGS.limitTextLength);
      }
      // highlight summary content
      else {
        if (!title.textContent) {
          title.textContent = resultItem.item.title;
        }
        // shifting text to starting nearly first matched index
        const textStartOffset = Math.max(indices[0][0] - SEARCH_CONFIGS.matchedStartOffset, 0);
        // truncate a text with ellipsis
        const text = value && value.substr(textStartOffset, SEARCH_CONFIGS.limitTextLength) + '...' || '';
        // reinitialize match indices by shifting the index according to first matched index
        const matchIndices = indices.map((matchIndex: number[]) => [matchIndex[0] - textStartOffset, matchIndex[1] - textStartOffset]);
        textHighlighter(text, matchIndices, summaryText, SEARCH_CONFIGS.limitTextLength);
      }
    });

    // append summary if text content has no matched ( matched only title )
    if (!summaryText.textContent) {
      summaryText.textContent = resultItem.item.text?.substr(0, SEARCH_CONFIGS.limitTextLength) + '...';
    }

    item.appendChild(title);
    item.appendChild(summaryText);
    item.appendChild(br);

    container.appendChild(item);
  });
};

searchButton?.addEventListener('click', () => {
  toggle(searchButton, 'close-button');
  toggle(searchContainer);
  hide(resultsContainer);
  searchInput?.focus();
  if (searchInput?.value) {
    searchInput.select();
  }
});

// close search menu on outside click
window.addEventListener('mousedown', (event: MouseEvent) => {
  const target = event.target as Element;
  if (!searchButton?.contains(target) && !searchContainer?.contains(target) && !resultsBody?.contains(target)) {
    closeSearch();
  }
});

searchInput.addEventListener('keyup', (event: KeyboardEvent) => {
  const searchValue = (event.target as HTMLInputElement).value.trim();

  resultsBody.innerHTML = ''; // reset old search results

  // hide result container if user input empty text
  if (!searchValue) {
    hide(resultsContainer);
    return;
  }

  const searchPattern = `'${searchValue.replace(/ /g, '|')}`; // search Items that include,OR `searchValue` ( pattern from fuse.js )
  const results = FUSE_SEARCH_INDEX.search(searchPattern);

  show(resultsContainer);

  if (!results.length) {
    show(resultsPlaceholder);
    if (resultsErrorMessage) {
      resultsErrorMessage.textContent = `No results found for query "${searchValue}"`;
    }
  }
  else {
    hide(resultsPlaceholder);
    outputSearchResults(results, resultsBody);
  }
});

/**
 * Initialize search index
 * @param routes routes data
 * @returns {void}
 */
export const initSearchIndex = (routes: RouteItem[]) : void => {
  const dummyElement = document.createElement('span');
  const searchIndex = routes.map(({ location, title, html }) => {
    dummyElement.innerHTML = html;
    return {
      title,
      location,
      text: dummyElement.textContent || ''
    };
  });
  FUSE_SEARCH_INDEX = new Fuse(searchIndex, SEARCH_CONFIGS.fuseOptions);
};
