import { query } from './utils/query';
import { show, hide } from './utils/visibility';
import { onOutsideClick } from './utils/events';

const sidebar = query('.sidebar');
const backdropOverlay = query('.backdrop-overlay');

// show sidebar when click on burger menu ( Mobile screen )
query('.mobile-menu').addEventListener('click', () => {
  show(sidebar);
  show(backdropOverlay);
});

onOutsideClick(sidebar, () => {
  hide(sidebar);
  hide(backdropOverlay);
});
