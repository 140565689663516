import { html, LitElement, PropertyValues, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { LanguageSupport } from '@codemirror/language';
import { html as mirrorHtml } from '@codemirror/lang-html';
import { css as mirrorCSS } from '@codemirror/lang-css';
import { javascript as mirrorJS } from '@codemirror/lang-javascript';
import { basicSetup, EditorState, EditorView } from '@codemirror/basic-setup';
import { ViewUpdate } from '@codemirror/view';

@customElement('live-editor')
export class liveEditor extends LitElement {
  @property()
  language = '';

  @property()
  snippet = '';

  /**
   * @override
   */
  protected firstUpdated (changedProperties: PropertyValues) : void {
    super.firstUpdated(changedProperties);

    const codeBlock = this.querySelector('.code-block');

    let mirrorLangExt : LanguageSupport | null = null;
    if (this.language === 'html') {
      mirrorLangExt = mirrorHtml();
    }
    else if (this.language === 'css') {
      mirrorLangExt = mirrorCSS();
    }
    else if (this.language === 'js') {
      mirrorLangExt = mirrorJS();
    }

    if (mirrorLangExt && codeBlock) {
      codeBlock.innerHTML = '';
      const mirrorCode = new EditorView({
        state: EditorState.create({
          doc: this.snippet,
          extensions: [
            basicSetup,
            mirrorLangExt,
            EditorView.updateListener.of((v: ViewUpdate) => {
              if (v.docChanged) {
                this.snippet = v.view.state.doc.toString();
                this.dispatchCodeChanged();
              }
            })
          ]
        })
      });
      codeBlock.appendChild(mirrorCode.dom);
    }
  }

  /**
   * DispatchCodeChanged handler is using for dispatch the codeChanged event
   * @returns {void}
   */
  private dispatchCodeChanged () {
    this.dispatchEvent(new CustomEvent('codeChanged', {
      detail: {
        language: this.language,
        snippet: this.snippet
      }
    }) as CustomEvent);
  }

  protected createRenderRoot () : ShadowRoot | Element {
    return this;
  }

  /**
   * A `TemplateResult` that will be used
   * to render the updated internal template.
   * @return Render template
   */
  protected render () : TemplateResult {
    return html`<div id="${this.language}Code" class="code-block"></div>`;
  }
}
