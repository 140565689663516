import type Fuse from 'fuse.js';

/**
 * Highlight matching text from match indices
 * @param text text to highlight
 * @param indices indices of the match in the string
 * @param container - container of matched text
 * @param limitTextLength - Limit text length of result
 * @returns {void}
 */
export const textHighlighter = (text: string, indices: number[][] | readonly Fuse.RangeTuple[], container: HTMLElement, limitTextLength: number): void => {
  indices.forEach((matchIndex: number[], i: number) => {
    const startOffset = matchIndex[0];
    const endOffset = matchIndex[1] + 1;
    const startOfNext = indices[i + 1] ? indices[i + 1][0] : text.length; // start offset of next matched

    if (endOffset > limitTextLength || startOffset > limitTextLength) {
      return;
    }

    const textStartIndex = i === 0 ? 0 : startOffset;

    // The part before matched text
    const beforeText = text.slice(textStartIndex, startOffset);

    // Matched text
    const highlightedText = text.slice(startOffset, endOffset);

    // Part after matched text
    // Till the end of text, or till next matched text
    const afterText = text.slice(endOffset, startOfNext);

    const fragment = new DocumentFragment();

    if (beforeText) {
      fragment.append(beforeText);
    }

    const markElem = document.createElement('mark');
    markElem.textContent = highlightedText;
    fragment.append(markElem);
    fragment.append(afterText);

    container.appendChild(fragment);
  });
};
