import { query } from './utils/query';
import { hide, toggle } from './utils/visibility';
import { onOutsideClick } from './utils/events';

const backdropOverlay = query('.backdrop-overlay');
const settingsContent = query('.settings-content');

const toggleSettingsVisibility = () => {
  toggle(settingsContent);
  toggle(backdropOverlay, 'desktop-visible');
};

// show settings menu when click on settings button
query('.settings-button').addEventListener('click', toggleSettingsVisibility);
// hide settings menu when click on back arrow ( Mobile screen )
query('.settings-close').addEventListener('click', toggleSettingsVisibility);

onOutsideClick(settingsContent, () => {
  hide(settingsContent);
  hide(backdropOverlay, 'desktop-visible');
});
