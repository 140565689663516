import { LitElement, html, TemplateResult, PropertyValues } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import { hide, show } from '../../utils/visibility';
import { query as querySelector } from '../../utils/query';
import { debounce } from '../../utils/debounce';

import { CodeSandbox } from './code-sandbox';
import { LiveConsole } from '../liveConsole/live-console';

import type { CodeSnippet } from './type';

import '../liveConsole/live-console';
import '../panelDragger/panel-dragger';

const TAB_ACTIVE_CLASS = 'active';
const DESKTOP_TAB_ACTIVE_CLASS = 'desktop-active';
const CODE_SESSION_KEY = 'code-panel-sizes';
const CONSOLE_SESSION_KEY = 'console-panel-sizes';

const placeholder = document.createElement('div');

let activeSandbox : CodeSandbox | null = null;

/**
 * Open sandbox in live code editor
 * @param sandbox Code sandbox element
 * @returns {void}
 */
export const editSandbox = (sandbox: CodeSandbox): void => {
  if (!activeSandbox) {
    const liveCode: LiveCode = querySelector('live-code');
    liveCode.hash = sandbox.getAttribute('hash') || '';
    liveCode.opened = true;
    sandbox.replaceWith(placeholder);
    activeSandbox = sandbox;
  }
};

/**
 * live-code - a live code editor on document
 */
@customElement('live-code')
export class LiveCode extends LitElement {
  @property()
  public opened = false;

  @property()
  public hash = '';

  @query('iframe')
  protected iframe! : HTMLIFrameElement;

  @query('.lang-selector')
  protected langSelector! : HTMLElement;

  @query('.viewer-panel')
  protected viewer! : HTMLElement;

  @query('.code-panel')
  protected codeBlocks! : HTMLElement;

  @query('live-console')
  protected liveConsole! : LiveConsole;

  @query('.iframe-panel')
  protected iframeContainer! : HTMLElement;

  private liveCodeUpdate = debounce(this.renderIframe, 1000);

  protected content : CodeSnippet[] = [];
  protected supportedLanguages : string[] = ['html', 'css', 'js'];

  /**
   * Updates the element
   * @param changedProperties Properties that has changed
   * @returns {void}
   */
  protected updated (changedProperties: PropertyValues): void {
    super.updated(changedProperties);
    if (changedProperties.has('hash')) {
      // get panel sizes data from session storage and set to the panels
      if (this.hash) {
        const codeAndViewerPanelSizes = sessionStorage.getItem(`${CODE_SESSION_KEY}-${this.hash}`);
        const viewerAndConsolePanelSizes = sessionStorage.getItem(`${CONSOLE_SESSION_KEY}-${this.hash}`);
        this.setPanelSizes([this.codeBlocks, this.viewer], codeAndViewerPanelSizes);
        if (viewerAndConsolePanelSizes) {
          this.setPanelSizes([this.iframeContainer, this.liveConsole], viewerAndConsolePanelSizes);
          this.liveConsole.opened = true;
        }
      }
    }
    if (this.liveConsole) {
      this.liveConsole.clear();
    }
  }

  /**
   * Set/Reset panel size based on session data
   * @param panels pair of panels
   * @param panelSizesData panel sizes session data
   * @returns {void}
   */
  private setPanelSizes (panels: HTMLElement[], panelSizesData: string | null): void {
    if (panelSizesData) {
      const sizes = panelSizesData.split(',') || [];
      panels[0].style.flexBasis = sizes[0];
      panels[1].style.flexBasis = sizes[1];
    }
    else {
      panels.forEach(panel => panel.style.removeProperty('flex-basis'));
    }
  }

  /**
   * Handler to filter supporting languages for rendering it in the iframe
   * @returns {void}
   */
  private filterSupportLanguages (): void {
    if (activeSandbox) {
      this.content = this.supportedLanguages.map(language => {
        const snippet = activeSandbox?.snippets.find(value => value.language === language);
        return snippet || { language, snippet: '' };
      });
    }
  }

  /**
   * Handler to build language selection tab
   * @returns languages selector template
   */
  private get langSelectorTemplate (): TemplateResult {
    this.filterSupportLanguages();
    const codeActiveTab = sessionStorage.getItem(`code-active-tab-${this.hash}`);
    const desktopActiveTab = sessionStorage.getItem(`desktop-active-tab-${this.hash}`);

    if (activeSandbox && codeActiveTab === 'preview') {
      show(this.langSelector.querySelector('.preview-btn'), TAB_ACTIVE_CLASS);
      this.codeBlocks.classList.add('hide');
      show(this.viewer, TAB_ACTIVE_CLASS);
    }

    const languageSelectorElement = this.content.map((value, index) => {
      let activeClass = (codeActiveTab === value.language) ? TAB_ACTIVE_CLASS : '';
      if (!codeActiveTab && index === 0) {
        activeClass = TAB_ACTIVE_CLASS;
      }

      const desktopActiveClass = (desktopActiveTab === value.language) ? DESKTOP_TAB_ACTIVE_CLASS : '';

      return html`
        <a class="lang-item ${activeClass} ${desktopActiveClass}"
          data-language-name="${value.language}"
          @click=${this.handleCodeSection}>${value.language.toUpperCase()}</a>`;
    });

    return html`${ languageSelectorElement }`;
  }

  /**
   * Handler to build code editor
   * @returns code block template
   */
  private get codeBlockTemplate (): TemplateResult {
    this.filterSupportLanguages();
    let codeActiveTab = sessionStorage.getItem(`code-active-tab-${this.hash}`);
    if (activeSandbox && codeActiveTab === 'preview') {
      codeActiveTab = sessionStorage.getItem(`desktop-active-tab-${this.hash}`);
    }

    const codeBlockElement = this.content.map((value, index) => {
      let activeClass = (codeActiveTab && codeActiveTab === value.language) ? TAB_ACTIVE_CLASS : '';
      activeClass = (!codeActiveTab && index === 0) ? TAB_ACTIVE_CLASS : activeClass;

      return html`
        <div class="language-block ${value.language}-code ${activeClass}">
          <div id="${value.language}Code" class="code-block">
            <live-editor language="${value.language}" snippet="${value.snippet}" @codeChanged=${this.codeChangedListener}></live-editor>
          </div>
        </div>`;
    });

    return this.opened ? html`${ codeBlockElement }` : html``;
  }

  /**
   * Building document source for display in the iframe
   * @returns {void}
   */
  private renderIframe (): void {
    this.updateCodeSandbox();
  }

  /**
   * Handler to update the code sandbox following live code editor
   * @return {void}
   */
  private updateCodeSandbox (): void {
    if (activeSandbox) {
      activeSandbox.snippets = this.content;
      activeSandbox.reload();
    }
  }

  /**
   * Handler to active code block in the code editor
   * @param event element event
   * @returns {void}
   */
  private handleCodeSection (event: Event): void {
    const langSelected = event.target as HTMLElement;
    let selectedTab = '';

    // change active language tab
    hide(this.langSelector.querySelector(`.${TAB_ACTIVE_CLASS}`), TAB_ACTIVE_CLASS);
    hide(this.langSelector.querySelector(`.${DESKTOP_TAB_ACTIVE_CLASS}`), DESKTOP_TAB_ACTIVE_CLASS);
    show(langSelected, TAB_ACTIVE_CLASS);

    if (!langSelected.classList.contains('preview-btn')) {
      this.codeBlocks.classList.remove('hide');
      hide(this.viewer, TAB_ACTIVE_CLASS);
      hide(this.codeBlocks.querySelector(`.${TAB_ACTIVE_CLASS}`), TAB_ACTIVE_CLASS);
      sessionStorage.setItem(`desktop-active-tab-${this.hash}`, '');

      if (langSelected.dataset.languageName) {
        selectedTab = langSelected.dataset.languageName;
        show(this.codeBlocks.querySelector(`.${langSelected.dataset.languageName}-code`), TAB_ACTIVE_CLASS);
      }
    }
    else {
      selectedTab = 'preview';
      this.codeBlocks.classList.add('hide');
      show(this.viewer, TAB_ACTIVE_CLASS);

      const codeActiveTab = sessionStorage.getItem(`code-active-tab-${this.hash}`) || this.content[0].language;
      if (codeActiveTab !== 'preview') {
        sessionStorage.setItem(`desktop-active-tab-${this.hash}`, codeActiveTab);
      }

      const desktopActiveTab = (codeActiveTab === 'preview') ? sessionStorage.getItem(`desktop-active-tab-${this.hash}`) || this.content[0].language : codeActiveTab;
      show(this.langSelector.querySelector(`[data-language-name=${desktopActiveTab}]`), DESKTOP_TAB_ACTIVE_CLASS);
    }

    sessionStorage.setItem(`code-active-tab-${this.hash}`, selectedTab);
  }

  /**
   * Handler to close the live code window
   * @returns {void}
   */
  private closeLiveCodePreview (): void {
    this.opened = false;
    if (activeSandbox) {
      hide(this.langSelector.querySelector('.preview-btn'), TAB_ACTIVE_CLASS);
      hide(this.viewer, TAB_ACTIVE_CLASS);
      this.codeBlocks.classList.remove('hide');

      placeholder.replaceWith(activeSandbox);
      this.content = [];
      activeSandbox = null;
    }
  }

  /**
   * codeChangedListener is a event listener for code change event from code editor
   * and update the iframe with latest code
   * @param event custom event from live editor
   * @returns {void}
   */
  private codeChangedListener (event: CustomEvent<CodeSnippet>): void {
    const contentIndex = this.content.findIndex((obj => obj.language === event.detail.language));
    this.content[contentIndex].snippet = event.detail.snippet;
    this.liveCodeUpdate();
  }

  protected createRenderRoot () : ShadowRoot | Element {
    return this;
  }

  /**
   * A `TemplateResult` that will be used
   * to render the updated internal template.
   * @return Render template
   */
  protected render (): TemplateResult {
    return html`
      <div class="pandora-code-viewer ${classMap({ active: this.opened })}">
        <div class="pandora-toolbar">
          <div class="lang-selector lang-content-selector">
            ${this.langSelectorTemplate}
            <a class="lang-item preview-btn" @click="${this.handleCodeSection}">PREVIEW</a>
          </div>
          <button class="close-btn action-button" @click="${this.closeLiveCodePreview}">
            <svg width="50" height="50" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" focusable="false" stroke="currentColor"><g fill="none" fill-rule="evenodd"><path d="M12 12L4 4M12 4l-8 8"></path></g></svg>
          </button>
        </div>
        <div class="viewer-container">
          <div class="code-panel">
            ${this.codeBlockTemplate}
          </div>
          <panel-dragger horizontal .sessionKey="${CODE_SESSION_KEY}-${this.hash}">
          </panel-dragger>
          <div class="viewer-panel">
            <div class="iframe-panel">
              ${activeSandbox}
            </div>
            <panel-dragger vertical .sessionKey="${CONSOLE_SESSION_KEY}-${this.hash}">
            </panel-dragger>
            <live-console></live-console>
          </div>
        </div>
      </div>
    `;
  }
}
