import { queryAll } from './utils/query';
import { LanguageSelector } from './elements/language-selector';

declare type ValueChangedEvent = CustomEvent<{
  value: string
}>;

declare global {
  interface GlobalEventHandlersEventMap {
    'value-changed': ValueChangedEvent;
  }
}

const LANGUAGES_ALIAS: { [key: string]: string } = {
  'javascript': 'js',
  'typescript': 'ts'
};

/**
 * Extract code language from the class name of pre element
 * @param preElement pre tag element
 * @returns code language
 */
const extractCodeLanguage = (preElement: Element | null): string => {
  const codeElement = preElement?.querySelector('code');
  if (!codeElement) {
    return '';
  }
  return codeElement.className.split('language-')[1].toLowerCase();
};

/**
 * Show/hide code block depends on current active language
 * @param event value change event of language selector
 * @param preBlock container of code blocks
 * @returns {void}
 */
const setActiveCodeBlock = (event: ValueChangedEvent, preBlock: HTMLElement): void => {
  preBlock.querySelector('.active')?.classList.remove('active');
  preBlock.querySelector(`code.language-${event.detail.value}`)?.parentElement?.classList.add('active');
};

/**
 * Determines whether the languages includes a certain language among its entries,
 * @param languages defined languages in meta data
 * @param language current language
 * @returns is defined language
 */
const isDefinedLanguage = (languages: string[], language: string) => {
  return languages.includes(language) || languages.includes(LANGUAGES_ALIAS[language]);
};

/**
 * Initialize language tab bar
 * @param languages list of supported languages
 * @returns {void}
 */
export const initLanguageTabBar = (languages: string[] = []) : void => {
  languages = languages.map(lang => lang.toLowerCase());

  const preElements = queryAll<HTMLPreElement>('.content > pre');
  const preBlockRegistry: HTMLPreElement[] = [];

  let languageSelector: LanguageSelector;

  // Merge adjacent pre blocks into a group
  for (const preElement of preElements) {
    let nextSibling = preElement.nextElementSibling;

    // Reject if pre element is already merged into a group
    if (!preBlockRegistry.includes(preElement) && nextSibling && nextSibling.tagName === 'PRE') {
      const currentCodeLang = extractCodeLanguage(preElement);
      
      if (isDefinedLanguage(languages, currentCodeLang)) {
        const tabLanguages = [];
        const preItems = [preElement];
        const preBlock = document.createElement('div');
        let nextCodeLang = extractCodeLanguage(nextSibling);
        
        preElement.classList.add('active'); // set default active code block
        preBlock.className = 'pre-block';

        tabLanguages.push({ label: LANGUAGES_ALIAS[currentCodeLang] || currentCodeLang, value: currentCodeLang });
        
        languageSelector = document.createElement('language-selector') as LanguageSelector;
        const previousElementSibling = preElement.previousElementSibling;

        while (nextSibling && nextSibling.tagName === 'PRE' && isDefinedLanguage(languages, nextCodeLang)) {
          preItems.push(nextSibling as HTMLPreElement);
          preBlockRegistry.push(nextSibling as HTMLPreElement);
          nextCodeLang = extractCodeLanguage(nextSibling);
          tabLanguages.push({ label: LANGUAGES_ALIAS[nextCodeLang] || nextCodeLang, value: nextCodeLang });
          nextSibling = nextSibling.nextElementSibling;
        }
 
        if (preItems.length > 1) {
          preBlock.append(...preItems);
          previousElementSibling?.insertAdjacentElement('afterend', preBlock);
          languageSelector.languages = tabLanguages;
          languageSelector.value = tabLanguages[0].value;
          languageSelector.addEventListener('value-changed', (e) => setActiveCodeBlock(e, preBlock));
          preBlock.prepend(languageSelector);
        }
      }
    }
  }
};
